const baseURL = process.env.REACT_APP_API_URL;
export const fetchOdometer = () => {
  console.log(baseURL);
  return fetch(`${baseURL}/v1/odometer`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((err) => {
      console.error("Error fetching data:", err.message);
      throw err; // re-throw to handle it in the component
    });
};

export const fetchChargesCost = () => {
  console.log(baseURL);
  return fetch(`${baseURL}/v1/charges/cost`)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .catch((err) => {
      console.error("Error fetching data:", err.message);
      throw err; // re-throw to handle it in the component
    });
};
