import { MdBarChart, MdDashboard } from "react-icons/md";
import React, { useEffect, useState } from "react";
import { fetchOdometer, fetchChargesCost } from "api/apiClient";
import TotalSpent from "./components/TotalSpent";
import WeeklySavings from "./components/WeeklySavings";

import Widget from "components/widget/Widget";
import WidgetLegend from "components/widget/WidgetLegend";

const Dashboard = () => {
  const [odometerKm, setOdometerKm] = useState({ km: -1, unit: "km" });
  const [totalCost, setTotalCost] = useState({ cost: -1 });

  useEffect(() => {
    fetchOdometer()
      .then((data) => {
        setOdometerKm(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetchChargesCost()
      .then((data) => {
        setTotalCost(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
        <Widget
          icon={<MdBarChart className="h-7 w-12" />}
          title={"Kilómetros totales"}
          subtitle={odometerKm.km + " " + odometerKm.unit}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Gasto en cargas"}
          subtitle={totalCost.cost + " €"}
        />
        <WidgetLegend
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Gasto estimado en diesel"}
          legend={"Si hubiese hecho el mismo kilometraje en coche diesel"}
          subtitle={Math.round((odometerKm.km / 100) * 6 * 1.6) + " €"}
        />
      </div>

      {/* Charts */}

      {/* <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <WeeklySavings />
      </div> */}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        {/* <div>
          <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />
        </div> */}

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div> */}

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
