import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import { MdBarChart } from "react-icons/md";

const routes = [
  {
    name: "Estadísticas principales",
    layout: "/general",
    path: "default",
    icon: <MdBarChart className="h-6 w-6" />,
    component: <MainDashboard />,
  },
];
export default routes;

/*

{
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "data-tables",
    component: <DataTables />,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <MdLock className="h-6 w-6" />,
    component: <SignIn />,
  },
  {
    name: "RTL Admin",
    layout: "/rtl",
    path: "rtl",
    icon: <MdHome className="h-6 w-6" />,
    component: <RTLDefault />,
  },
  */
